export const events = [
  {
    title: "Holy Qurbana",
    date: "2024-12-15",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Christmas Service",
    date: "2024-12-24",
    time: "6pm",
    description: "Chief Celebrant: Rev. Fr. Binil Raj",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-01-04",
    time: "9am",
    description: "Sunday School Food Festival",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-01-19",
    time: "9am",
    description: "Sunday School Annual Day",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-02-01",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Fast of Nineveh",
    date: "2025-02-12",
    time: "TBC",
    description: "Evening Prayer followed by Holy Qurbana",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-02-16",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-03-01",
    time: "9am",
    description: "Annual General Body Meeting",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-03-16",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Feast of Annunciation to St. Mary",
    date: "2025-03-24",
    time: "TBC",
    description: "Vachanipu Perunal. Evening Prayer followed by Holy Qurbana",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },

  {
    title: "Holy Qurbana",
    date: "2025-04-05",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-04-20",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-05-03",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-05-18",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-06-07",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-06-15",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-07-05",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-07-20",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-08-02",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-08-17",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-09-06",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-09-21",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-10-04",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-10-19",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-11-01",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-11-16",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-12-06",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
  {
    title: "Holy Qurbana",
    date: "2025-12-21",
    time: "9am",
    description: "",
    location: (
      <>
        St. Cecilia R.C. Church <br />
        Ryhope Road
        <br /> Sunderland
        <br />
        SR2 7TG
      </>
    ),
  },
];
