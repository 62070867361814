import { Container } from "react-bootstrap";
import "../Slideshow/slideshow.css";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

const Slideshow = ({ photos }) => {
  return (
    <Container>
      {photos ? (
        <Carousel className="carousel" interval={null}>
          {photos.map((photo) => (
            <Carousel.Item>
              <Image
                className="d-block w-100"
                src={photo.src}
                rounded
                loading="lazy"
              />
              <Carousel.Caption>
                <h3 className="slideshow-caption">{photo.caption}</h3>
                <h4 className="slideshow-description">{photo.year}</h4>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        ""
      )}
    </Container>
  );
};

export default Slideshow;
