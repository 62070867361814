import Container from "react-bootstrap/Container";
import { useTitle } from "react-use";
import Note from "../components/Note/Note";
import Title from "../components/Title/Title";
import Text from "../components/Text/Text";
import ContentCard from "../components/ContentCard/ContentCard";
import Photo from "../components/Photos/Photo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import BibleVerse from "../services/BibleVerse";

import OSSAE from "../assets/logos/ossae.png";
import OCYM from "../assets/logos/ocym.png";
import MMVS from "../assets/logos/mmvs.png";
import AMOSS from "../assets/logos/amoss.png";
import SmiocOriginal from "../assets/media/home/smiocoriginal.jpg";
import StMarys from "../assets/media/home/stmarys.png";

const Home = () => {
  useTitle("SMIOC - Home");
  return (
    <Container>
      <Container>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <Fade big cascade>
              <Photo src={SmiocOriginal} />
            </Fade>
          </Col>

          <Col md={6} xs={6}>
            <Fade big right>
              <div className="rightpanelcontents">
                <p className="welcome"> Welcome to </p>
                <Photo src={StMarys} />
                <p className="churchname">
                  {" "}
                  Indian Orthodox Church
                  <br />
                  Sunderland
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>

      <Container>
        <br />
        <Note description="We invite you to join us in worship on the first Saturday and third Sunday of every month." />

        <Title title="Spiritual Organisations" />
        <Text
          className="center"
          description={
            "We recognise our responsibility in learning and imparting values and tradition to our next generation, we invite you to join our spirtual organisations."
          }
        />

        <Fade big cascade>
          <Row className="justify-content-md-center">
            <Col lg={6} md={12}>
              <ContentCard
                title="AMOSS"
                name="Akhila Malankara Orthodox Shusrushaka Sangham"
                description="Join our acolytes to create awareness in the faith, tradition and practices in our Holy Qurbana, other sacraments and important days of Malankara Orthodox Syrian Church."
                href="https://indianorthodoxuk.org/spiritual-wings/akhila-malankara-orthodox-shusrushaka-sangham"
                image={AMOSS}
                cardtype="home"
              />
              <br />
            </Col>
            <Col lg={6} md={12}>
              <ContentCard
                title="MMVS"
                name="Martha Mariam Vanitha Samajam"
                description="Join our Women’s wing of the Malankara Orthodox Church of India. It is one of the major spiritual organization of the church combining all the female members."
                href="https://indianorthodoxuk.org/spiritual-wings/martha-mariam-vanitha-samajam"
                image={MMVS}
                cardtype="home"
              />
              <br />
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col lg={6} md={12}>
              <ContentCard
                title="OSSAE"
                name="Orthodox Sunday School Association of the East"
                description="Join the spiritual nurturing of children, by bringing them up in the knowledge and fellowship of Jesus Christ and His Church. We teach the OSSAE ciriculum ranging from classes 1 - 10."
                href="https://ossae.org"
                image={OSSAE}
                cardtype="home"
              />
              <br />
            </Col>
            <Col lg={6} md={12}>
              <ContentCard
                title="OCYM"
                name="Orthodox Christian Youth Movement of the East"
                description="Join our Youth-wing who contributes to the goodness of the Church and community in worship, study and service. It aims at moulding the minds and visions against the background of the contemporary issues."
                href="https://ocymonline.org"
                image={OCYM}
                cardtype="home"
              />
              <br />
            </Col>
          </Row>
        </Fade>

        <Fade>
          <Title title="Verse of the Day" />
          <Note verse={<BibleVerse />} />
        </Fade>
      </Container>
      <br />
      <br />
    </Container>
  );
};

export default Home;
